<template>
<div class="card border-0 mb-4 box-shadow h-xl-300">              
    <div :style="currentImg"></div>               
    <div class="card-body px-0 pb-0 d-flex flex-column align-items-start">
        <h2 class="h4 font-weight-bold">
            <a class="text-dark" :href="link"><slot name="title"></slot></a>
        </h2>
        <p class="card-text">
            <slot name="description"></slot>
        </p>
        <div>
            <small class="d-block"><a class="text-muted" href="./author.html"><slot name="author"></slot></a></small>
            <small class="text-muted"><slot name="date"></slot> &middot; <slot name="timetoread"></slot></small>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'CurrentBlurb',
  props: [    "link",
    "img"]

,
  data() {
    return {
      newsletters: this.$store.state.newsletters
    }
  },
  mounted() {
    
  },
  computed: {
    currentblurb() {
      return this.newsletters.slice().reverse();
    },
    currentImg() {

      return "background-image: url(" +   this.currentblurb[0].img    + "); height: 200px;    background-size: cover;    background-repeat: no-repeat;background-position:center center;";
    }

  }
}

</script>

