<template>
<div class="home">
  <SiteHeader/>
  <MoreContent/>
</div>
</template>

<script>

// @ is an alias to /src
import SiteHeader from '@/components/SiteHeader.vue'
import MoreContent from '@/components/MoreContent.vue'
export default {
  name: 'HomeView',
  components: {
    SiteHeader,
    MoreContent
  },
  data() {
    return {
      newsletters: this.$store.state.newsletters
    }
  },
  mounted() {

  },
  computed: {
    blurbs() {
      var len = this.newsletters.lenght();
      console.log(len);
      return this.newsletters.slice(0,len).reverse();
    },
    currentblurb() {
      return this.newsletters.slice().reverse();
    }

  }
}

</script>
