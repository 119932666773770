<template>
<div class="mb-3 d-flex align-items-center">
    <img class="ab-img" height="80" :src="img">
    <div class="pl-3 ab-content">
        <h2 class="mb-2 h6 font-weight-bold">
            <a class="text-dark" :href="link"><slot name="title"></slot></a>
        </h2>
        <div class="card-text text-muted small">
            <slot name="description"></slot>
        </div>
        <small class="text-muted"><slot name="date"></slot> &middot;<slot name="timetoread"></slot></small>
    </div>
</div>
</template>


<script>
export default {
  name: 'HomeView',
  props: [    "link",
    "img"]

,
  data() {
    return {
    }
  },
  mounted() {
    
  }
}

</script>
<style  lang="scss">

.ab-content {
  margin-left:120px;
}
</style>