import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newsletters:[
      {"id":"001","src":"/article/scattered-future-newsletter-001-08-08-2023/","title":"Scattered Future Newsletter #001","description":"Welcome to the intial post of the Scattered Future newsletter.","author":"Ivan Reyes","date":"August 08, 2023","img":"/img/scattered-future-splash-01.jpg","video":"/video/scattered-future-splash-01-circle.mp4","timetoread":"10 min read"},
      {"id":"002","src":"/article/scattered-future-newsletter-002-08-15-2023/","title":"Scattered Future Newsletter #002","description":"The second installment of the Scattered Future newsletter!","author":"Ivan Reyes","date":"August 15, 2023","img":"/img/scattered-future-splash-02.jpg","video":"","timetoread":"10 min read"},
      {"id":"003","src":"/article/scattered-future-newsletter-003-08-22-2023/","title":"Scattered Future Newsletter #003","description":"Updates on Robots, A.I. and The Moon for the week of Aug 21st","author":"Ivan Reyes","date":"August 22, 2023","img":"/img/scattered-future-splash-03.jpg","video":"","timetoread":"10 min read"},
      {"id":"004","src":"/article/scattered-future-newsletter-004-08-29-2023/","title":"Scattered Future Newsletter #004","description":"Llamas, biotech and more AI updates for the week of Aug 28st","author":"Ivan Reyes","date":"August 29, 2023","img":"/img/scattered-future-splash-04.jpg","video":"","timetoread":"10 min read"},
      {"id":"005","src":"/article/scattered-future-newsletter-005-09-04-2023/","title":"Scattered Future Newsletter #005","description":"AI updates for the week of Sept 4th","author":"Ivan Reyes","date":"September 04, 2023","img":"/img/scattered-future-splash-05.jpg","video":"","timetoread":"10 min read"},
      {"id":"006","src":"/article/scattered-future-newsletter-006-09-12-2023/","title":"Scattered Future Newsletter #006","description":"Even more AI updates for the week of Sept 11th","author":"Ivan Reyes","date":"September 11, 2023","img":"/img/scattered-future-splash-06.jpg","video":"","timetoread":"10 min read"},
      {"id":"007","src":"/article/scattered-future-newsletter-007-09-18-2023/","title":"Scattered Future Newsletter #007","description":"updates for the week of Sept 18th - robots,ai, space and more! ","author":"Ivan Reyes","date":"September 18, 2023","img":"/img/scattered-future-splash-07.jpg","video":"","timetoread":"10 min read"},
      {"id":"008","src":"/article/scattered-future-newsletter-008-09-25-2023/","title":"Scattered Future Newsletter #008","description":"updates for the week of Sept 25th - the AIs are starting to listen to and see the outside world, whispers of AGI and more this week. Things are getting weirder","author":"Ivan Reyes","date":"September 25, 2023","img":"/img/scattered-future-splash-08.jpg","video":"","timetoread":"10 min read"},
      {"id":"009","src":"/article/scattered-future-newsletter-009-10-02-2023/","title":"Scattered Future Newsletter #009","description":"updates for the week of Oct 2nd - autonomous aircraft, robot burrito makers, 3d printed moon bases and skin, mind uploading, and ai designed robots! ","author":"Ivan Reyes","date":"October 2, 2023","img":"/img/scattered-future-splash-09.jpg","video":"","timetoread":"10 min read"},
      {"id":"010","src":"/article/scattered-future-newsletter-010-10-09-2023/","title":"Scattered Future Newsletter #010","description":"AI updates for the week of Oct 9th","author":"Ivan Reyes","date":"October 09, 2023","img":"/img/scattered-future-splash-10.jpg","video":"","timetoread":"10 min read"},
      {"id":"011","src":"/article/scattered-future-newsletter-011-10-23-2023/","title":"Scattered Future Newsletter #011","description":"AI updates for the week of Oct 23th","author":"Ivan Reyes","date":"October 23, 2023","img":"/img/scattered-future-splash-11.jpg","video":"","timetoread":"10 min read"},
      {"id":"012","src":"/article/scattered-future-newsletter-012-04-04-2024/","title":"Scattered Future Newsletter #012","description":"AI updates for the week of April 4th, 2024","author":"Ivan Reyes","date":"April 04, 2024","img":"/img/scattered-future-splash-12.jpg","video":"/video/sf_012.mp4","timetoread":"10 min read"},
      {"id":"013","src":"/article/scattered-future-newsletter-013-10-21-2024/","title":"Scattered Future Newsletter #013","description":"AI updates for the week of October 21th, 2024","author":"Ivan Reyes","date":"October 21, 2024","img":"/img/scattered-future-splash-13.png","video":"","timetoread":"10 min read"},
    ]
  },
  getters: {
    getCurrentArticle: (state) => (id) => {
      return state.newsletters.find(item => item.id === id)
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
