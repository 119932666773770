<template>
  <div id="app">
    <SiteNav/>
    <router-view/>
  <SiteFooter/>
  </div>
</template>

<script>

// @ is an alias to /src
import SiteNav from '@/components/SiteNav.vue'
import SiteFooter from '@/components/SiteFooter.vue'
export default {
  name: 'HomeView',
  components: {
    SiteNav,
    SiteFooter
  }
}

</script>
<style lang="scss">
a {
  color:#666;
}
a:hover {
  color:black;
}
.router-link-active {
  color:#333;
}
.nl-date {
  font-size:14px;
  color:#666;
}
.nl-description {
  font-size:16px;
}
.nl-link {
  font-size:14px;
  line-height:2em;
}
.nl-quote {
  margin:0px 0px 0px 0px;
  padding:20px;
  background-color:#e1e1e1;
  border-radius:10px;
}
.nl-tweet {
  border:1px solid #e1e1e1;
  margin:0px;
}
.newsletter h4 {
  margin-top:20px;
  color:rgb(147, 0, 0);
}
.newsletter h5 {
  margin-top:20px;
  color:rgb(147, 0, 0);
}

.nav-item {
  margin-right:20px;
}
.navbar .container {

}
.avatar-circle {
  width:50px;
  height:50px;
  border-radius:25px;
}
</style>
