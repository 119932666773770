<template>
        <div class="container pt-4 pb-4  article-blurbs">
    <div class="row">
      <div class="col-lg-6">
        <CurrentBlurb :img="currentblurb[0].img" :link="currentblurb[0].src">
          <span slot="title">{{currentblurb[0].title}}</span>
          <span slot="description">{{currentblurb[0].description}}</span>
          <span slot="timetoread">{{currentblurb[0].timetoread}}</span>
          <span slot="date">{{currentblurb[0].date}}</span>
        </CurrentBlurb>
      </div>
      <div class="col-lg-6">
        <div class="flex-md-row mb-4 box-shadow h-xl-300 ">
        <ArticleBlurb v-for="blurb in blurbs" :key="blurb.id" :img="blurb.img" :link="blurb.src">
          <span slot="title">{{blurb.title}}</span>
          <span slot="description">{{blurb.description}}</span>
          <span slot="timetoread">{{blurb.timetoread}}</span>
          <span slot="date">{{blurb.date}}</span>
        </ArticleBlurb>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CurrentBlurb from '@/components/CurrentBlurb.vue'
import ArticleBlurb from '@/components/ArticleBlurb.vue';
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
CurrentBlurb,
ArticleBlurb
  },
  data() {
    return {
      newsletters: this.$store.state.newsletters
    }
  },
  mounted() {

  },
  computed: {
    blurbs() {
      var len = this.newsletters.length - 1;
      console.log(len);
      return this.newsletters.slice(0,len).reverse();
    },
    currentblurb() {
      return this.newsletters.slice().reverse();
    }

  }
}
</script>
<style lang="scss">
.article-blurbs {
  height:600px;
  border:0px solid red;
}
</style>